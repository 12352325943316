import React, { Component } from 'react'
import Layout from '../components/layout'

class Listings extends Component {
  state = {}
  render() {
    return (
      <Layout>
        <p>this is the Listings page</p>
      </Layout>
    )
  }
}

export default Listings
